import contructor_int from "../../../../images/constructor.svg"
import defender from "../../../../images/defens.svg"
import {Feature} from "./Feature/Feature";

export function Features() {
    const data = [
        {
            type: 'original',
            title: 'удобный Конструктор интеграций',
            infoTitle: [],
            listText: [
                'Легкость интеграции: Создавайте и настраивайте интеграции с другими системами и сервисами без необходимости в программировании, обеспечивая бесшовную работу вашего бизнеса.',
                'Или обратитесь к нашему специалисту, которому достаточно просто скинуть документацию'
            ],
            image: contructor_int
        },
        {
            type: 'reverse',
            title: 'Безопасность',
            infoTitle: [
                'Чекер на фрод',
                'Черный список'
            ],
            listText: [
                'Надежная защита от мошенничества: Используйте чекер на фрод для выявления и предотвращения мошеннических действий, обеспечивая безопасность ваших данных и финансов.',
                'Блокировка нежелательных контактов: Управляйте черным списком для блокировки нежелательных лидов и партнеров, защищая ваш бизнес от мошенничества и некачественного трафика'
            ],
            image: defender
        }
    ];

    return (
        <section className="features">
            {data.map((info, index) => (
                <Feature
                    key={index}
                    image={info.image}
                    title={info.title}
                    infoTitle={info.infoTitle}
                    listText={info.listText}
                    classType={info.type}
                />
            ))}
        </section>
    );
}