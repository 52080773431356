import React, { useState } from 'react';
import styled from 'styled-components';
import styles from './services.module.scss';
import robotIcon from '../../../../icons/robot-icon.svg';
import alienIcon from '../../../../icons/alien-btn-icon.svg';
import {Switch} from "./SwitchBtn/SwitchBtn";
import {Item} from "./ServiceItem/ServiceItem";


const ServicesBlock = styled.div`
    background-color: ${(props) => (props.isOn ? '#1F4560' : '#fff')}; // Измените цвета по вашему желанию
    padding: 35px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: space-around;
    max-width: 70%;
    margin: 0 auto;
    color: #fff;
    border-radius: 57px;
`;

export function Services() {
    const services = [
        {
            title: 'Двухэтапная аутентификация',
            type: 'pro',
            listItems: ["- Высокий уровень безопасности: Защитите свои данные с помощью двухэтапной аутентификации, предотвращая несанкционированный доступ и обеспечивая безопасность информации."]
        },
        {
            title: 'Удобные статусы, которые работают на основе колл статусов',
            type: 'pro',
            listItems: ["Отслеживайте прогресс каждого лида с помощью интуитивно понятных статусов, основанных на колл статусах, для эффективного управления процессом."]
        },
        {
            title: 'Эффективное управление',
            type: 'start',
            listItems: [
                "Комбинируйте работу вебмастеров с гибкими настройками боксов для максимальной эффективности и результата.",
                "Автоматизируйте работу с разными сделками и брендами, и просто наблюдайте за их успехом"
            ]
        },
        {
            title: 'Персонализированные рабочие пространства',
            type: 'start',
            listItems: ["Создавайте уникальные рабочие места для каждой роли, обеспечивая максимальную эффективность и комфорт для каждого сотрудника."]
        },
        {
            title: 'Гибкая настройка прав доступа',
            type: 'start',
            listItems: ["Настраивайте права доступа для различных ролей и сотрудников, обеспечивая безопасность данных и контроль над информацией."]
        },
        {
            title: 'Мультиязычный интерфейс',
            type: 'start',
            listItems: ["Работайте на любом языке с мультиязычным интерфейсом, обеспечивая удобство для международных команд и клиентов."]
        },
        {
            title: 'Переотправка лидов',
            type: 'pro',
            listItems: ["Возможность дослать лиды: Переотправляйте лиды, которые зашли неправильное время или с другими параметрами, через случайные промежутки времени."]
        },
        {
            title: 'Создание собственных KPI',
            type: 'start',
            listItems: [" - Персонализированные метрики успеха: Настраивайте и отслеживайте собственные ключевые показатели эффективности, чтобы лучше оценивать и улучшать результаты вашей работы."]
        },
        {
            title: 'Инжект с автологином',
            type: 'pro',
            listItems: ["- Автоматическая отправка: Упростите процесс работы с лидами с помощью автоматического инжекта с автологином, обеспечивающим отправку множества лидов, по одному, через случайные промежутки времени, с прокси нужной страны и переходом по автологину."]
        },
        {
            title: 'Покупка доменов с привязкой на хост и клауд',
            type: 'pro',
            listItems: ["- Быстрая интеграция доменов: Покупайте и привязывайте домены к хостингу и облачным сервисам в один клик, упрощая управление веб-ресурсами"]
        },
        {
            title: 'Удобный конструктор отчетов',
            type: 'start',
            listItems: ["- Интуитивное создание отчетов: Создавайте подробные отчеты легко и быстро с помощью нашего конструктора, который не требует специальных технических навыков."]
        },
        {
            title: 'Конструктор интеграций',
            type: 'start',
            listItems: ["Создавайте и настраивайте интеграции с другими системами и сервисами без необходимости в программировании, обеспечивая бесшовную работу вашего бизнеса."]
        },
        {
            title: 'Статистика по каждому источнику трафика',
            type: 'start',
            listItems: ["Получайте подробную статистику по каждому источнику трафика, чтобы оптимизировать стратегии маркетинга и повышать эффективность кампаний."]
        },
        {
            title: 'Управление брендами',
            type: 'start',
            listItems: ["Создавайте, развивайте и управляйте несколькими брендами в одном месте, оптимизируя ваш маркетинг и усиливая присутствие на рынке."]
        },
    ];

    const [showFirstHalf, setShowFirstHalf] = useState(true);

    const toggleShow = () => {
        setShowFirstHalf(!showFirstHalf);
    };

    const displayedServices = showFirstHalf ? services.slice(0, 7) : services.slice(7, 14);

    return (
        <section className={styles.services}>
            <ServicesBlock isOn={!showFirstHalf} className={styles.services_block}>
                <Switch isOn={!showFirstHalf} onClick={toggleShow} />
                {displayedServices.map((item, index) => (
                    <Item
                        isOn={!showFirstHalf}
                        key={index}
                        title={item.title}
                        listItems={item.listItems}
                        type={item.type}
                    />
                ))}
            </ServicesBlock>
        </section>
    );
}

