import React from 'react';
import {Link} from 'react-router-dom';
import shared from './shared.module.scss'

const LinkButton = ({text, backgroundColor, borderType, onClick, to, children, ...props}) => {


     const scrollToForm = () => {

        if (!to) {
            if (onClick){
                return  onClick()
            }
           scrollToForm()
        }
    };
    const buttonStyle = {
        backgroundColor: backgroundColor,
        border: borderType,

    };

    return (
        <Link className={shared.link} {...props} props to={to} onClick={scrollToForm} style={buttonStyle}>
            {text} {children}
        </Link>
    );
};
export const scrollToForm = () => {
    const formSection = document.getElementById('formo4ka');
    if (formSection) {
        formSection.scrollIntoView({ behavior: 'smooth' });
    }
};
export default LinkButton;