import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import robotIcon from '../../../../../icons/robot-icon.svg';
import alienIcon from '../../../../../icons/alien-btn-icon.svg';

const SwitchContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 150px;
    height: 70px;
    background-color: ${(props) => (props.isOn ? '#fff' : '#1F4560')}; // Измените цвета по вашему желанию
    border-radius: 40px;
    cursor: pointer;
    position: relative;
    transition: background-color 0.3s ease;
`;

const SwitchCircle = styled.div`
    width: 60px;
    height: 60px;
    background-color: white;
    border-radius: 50%;
    position: absolute;
    top: 5px;
    left: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.3s ease;
    transform: ${(props) => (props.isOn ? 'translateX(80px)' : 'translateX(0)')};
`;

const Icon = styled.img`
   border-radius: 50%;
`;

export function Switch ({ onClick, isOn }) {
    return (
        <SwitchContainer isOn={isOn} onClick={onClick}>
            <SwitchCircle isOn={isOn}>
                <Icon src={isOn ? alienIcon : robotIcon} alt="icon" />
            </SwitchCircle>
        </SwitchContainer>
    );
};
