import axios from "axios";
const authToken = process.env.REACT_APP_TELEGRAM_BOT_TOKEN;
const chatId = process.env.REACT_APP_TELEGRAM_CHAT_ID;


const sendTelegramNotification = async (message: any) => {
    const url = `https://api.telegram.org/bot7398949990:AAEYH5wkr9F6kdpC_C-rnC-iq-OoAXcGO5s/sendMessage`;
    const payload = {
        chat_id: -4200130527,
        text: `🧟‍♀️New slave: 
        👽Name: ${message.name}
        ☠️Telegram: ${message.telegram}
        🐒Skype: ${message.skype}
        🌐email: ${message.email}
        ⚜️comment: ${message.comment}
        `,
        parse_mode: 'html'
    }
    return  axios.post(url, payload)
}

export default sendTelegramNotification;