import React from 'react';
import {scrollToForm} from "./Links";

const Button = ({ text, backgroundColor, borderType , ...props }) => {


    const buttonStyle = {
        background: backgroundColor,
        border: borderType,
        color: 'white',
        padding: '15px 35px',
        borderRadius: '50px',
        textAlign: 'center',
        textDecoration: 'none',
        fontSize: '16px',
        margin: '4px 2px',
        cursor: 'pointer',
    };

    return (
        <button {...props} onClick={scrollToForm} style={buttonStyle}>
            {text}
        </button>
    );
};

export default Button;