import Header from "../../widgets/Header/Header";
import {HeroBlock} from "./components/hero/HeroBlock";
import {CardList} from "./components/cards/Cards";
import {Footer} from "../../widgets/Footer/Footer";
import {Form} from "../../widgets/Form/Form";
import SliderPartners from "../../widgets/SliderPartners/SliderPartners";
import {DnkAnim} from "./components/DnkAnim/DnkAnim";

export function DevPage(){
    return(
        <>
            <Header/>
            <HeroBlock/>
            <CardList/>
            <DnkAnim/>
            <Form/>
            <SliderPartners/>
            <Footer/>
        </>
    );
}