import styles from "./servicesitem.module.scss";
import styled from 'styled-components';
import startIcon from '../../../../../icons/star-icon.svg'

const ServicesText = styled.div`
    color: ${(props) => (props.isOn ? 'rgba(189,186,186,0.85)' : '#9a9393')};
    line-height: 1.5;
`;

const ServicesTitle = styled.div`
    color: ${(props) => (props.isOn ? '#fff' : '#000')};
    font-size: 20px;
    font-weight: 700;
`;

const Icon = styled.img`
    margin-left: 8px;
    width: 20px;
    height: 20px;
`;


export function Item({title, listItems, type, isOn}) {

    return (
        <div className={styles.service_item}>
            <h4>
                <ServicesTitle className={styles.item_title} isOn={isOn}>
                    {title}
                    {type === 'pro' && <Icon src={startIcon} alt="Star Icon" />}
                </ServicesTitle>
            </h4>
            {listItems.map((text, index) => (
                <div key={index}>
                    <ServicesText className={styles.item_text} isOn={isOn}>{text}</ServicesText>
                </div>
            ))}
        </div>
    );
}