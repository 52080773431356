import {Card} from "./Card";
import webDev from "../../../../icons/webdev-icon.svg"
import setting from "../../../../icons/setting-icon.svg"
import cheap from "../../../../icons/cheap-icon.svg"
import brain from "../../../../icons/brain-icon.svg"
import secondSetings from "../../../../icons/second-setings.svg"
import clo from "../../../../icons/clo.svg"
import styles from "./card.module.scss"

export const CardList = () => {
    const cards = [
        {
            icon: webDev,
            title: 'ВЕБ РАЗРАБОТКА',
            listItems: [
                'Создание офферов и прилендов',
                'Проектирование и дизайн',
                'Установка и настройка Wordpress',
                'Подбор и кастомизация'
            ]
        },
        {
            icon: setting,
            title: 'НАСТРОЙКА СОБЫТИЙ',
            listItems: [
                'Установка Facebook Pixel на сайт',
                'Настройка Google Tag Manager',
                'Создание событий в Google Analytics'
            ]
        },
        {
            icon: clo,
            title: 'КЛОАКИНГ',
            listItems: [
                'Скрытие исходного кода и целевых страниц от ботов и модераторов',
                'Интеграция таких сервисов как Cloakit, Palladium, Hide.Click и т.д'
            ]
        },
        {
            icon: brain,
            title: 'ИНТЕГРАЦИЯ',
            listItems: [
                'Технические интеграции',
                'Поддержка CRM и других систем',
                'Настройка postback'
            ]
        },
        {
            icon: secondSetings,
            title: 'НАСТРОЙКА РАБОТЫ КЕЙТАРО',
            listItems: [
                'Установка и настройка трекера Keitaro для отслеживания рекламных кампаний',
                'Настройка postback',
                'Конфигурация серверов'
            ]
        },
        {
            icon: cheap,
            title: 'НАСТРОЙКА ДОМЕНОВ',
            listItems: [
                'Настройка и покупка доменов на Namecheap, GoDaddy, Cloudflare, DigitalOcean',
                'Настройка DNS',
                'Разработка телеграм бота'
            ]
        }
    ];

    return (
        <section className={styles.card_block}>
            <div className="container">
                <div className={styles.card_list_full}>
                    {cards.map((card, index) => (
                        <Card
                            key={index}
                            image={card.icon}
                            title={card.title}
                            listItems={card.listItems}
                        />
                    ))}
                </div>
            </div>
        </section>
    );
};