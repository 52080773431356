import {useForm} from 'react-hook-form';
import styles from "./form.module.scss"
import sticker from '../../icons/sticker-logo.svg'
import sendTelegramNotification from "../../api/telegramApi.ts";
import {useState} from "react";
import {ThankYouPopup} from "./ThankyouPopup";


export function Form() {
    const {register, handleSubmit, formState: {errors}, reset} = useForm();
    const [isPopupOpen, setIsPopupOpen] = useState(false);

    const handleClosePopup = () => {
        setIsPopupOpen(false);
    };

    const onSubmit = (data) => {
        setIsPopupOpen(true);
        sendTelegramNotification(data)
        reset()

    };
    return (
        <section className={styles.form_block} id='formo4ka'>
            <ThankYouPopup isOpen={isPopupOpen} onClose={handleClosePopup}/>
            <div className="container">
                <div className={styles.form_wrap}>
                    <div className={styles.formContainer}>
                        <h2>ЗАПРОСИТЕ ДЕМО-ВЕРСИЮ И ОЦЕНИТЕ ВСЕ ВОЗМОЖНОСТИ CRM!</h2>
                        <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
                            <div className={styles.formGroup} style={{gap: 20}}>
                                <div className={`${styles.formGroup} ${errors.name ? styles.grid50 : ''}`}>
                                    <input
                                        {...register('name', {required: 'Name is required'})}
                                        placeholder="Имя*"
                                        className={styles.input}
                                    />
                                    {errors.name && <span className={styles.error}>{errors.name.message}</span>}
                                </div>
                                <div className={`${styles.formGroup} ${errors.telegram ? styles.grid50 : ''}`}>
                                    <input
                                        {...register('telegram', {required: 'Telegram is required'})}
                                        placeholder="Telegram*"
                                        className={styles.input}
                                    />
                                    {errors.telegram && <span className={styles.error}>{errors.telegram.message}</span>}
                                </div>
                            </div>
                            <div className={styles.formGroup} style={{gap: 20}}>
                                <div className={`${styles.formGroup} ${errors.skype ? styles.grid50 : ''}`}>
                                    <input
                                        {...register('skype')}
                                        placeholder="Skype"
                                        className={styles.input}
                                    />
                                    {errors.skype && <span className={styles.error}>{errors.skype.message}</span>}
                                </div>
                                <div className={`${styles.formGroup} ${errors.email ? styles.grid50 : ''}`}>
                                    <input
                                        {...register('email', {
                                            required: 'Email is required', pattern: {
                                                value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
                                                message: 'Invalid email address'
                                            }
                                        })}
                                        placeholder="Email"
                                        className={styles.input}
                                    />
                                    {errors.email && <span className={styles.error}>{errors.email.message}</span>}
                                </div>
                            </div>
                            <div className={styles.formGroup}>
                          <textarea
                              {...register('comment')}
                              placeholder="Комментарий"
                              className={styles.textarea}></textarea>
                            </div>
                            <button type="submit" className={styles.button}>Отправить</button>
                        </form>
                    </div>
                    <div className={styles.sticker_container}>
                        <img src={sticker} className={styles.sticker_image} alt="sticker"/>
                    </div>
                </div>
            </div>
        </section>)
}