import Header from "./widgets/Header/Header";
import {Footer} from "./widgets/Footer/Footer";
import {CardsMain} from "./pages/mainPage/componetns/MainCard/Cards";
import {CommentSlider} from "./pages/mainPage/componetns/Comments/CommentSlider";
import {CounterDigits} from "./pages/mainPage/componetns/Countup/Counter";
import {Form} from "./widgets/Form/Form";
import SliderPartners from "./widgets/SliderPartners/SliderPartners";
import {Faq} from "./pages/mainPage/componetns/Faq/Faq";
import {useState} from "react";
import {TrialPopUp} from "./widgets/TrialPopup/TrialPopup";
import {Hero} from "./pages/mainPage/componetns/Hero/Hero";
import {Project} from "./pages/mainPage/componetns/Project/Project";


function App() {
    const [isPopUpOpen, setIsPopUpOpen] = useState(true);

    const handleClose = () => {
        setIsPopUpOpen(false);
    };
    return (
        <div className="App">
            <Header/>
            <div className="container">
                <Hero/>
                <Project/>
            </div>
            <CounterDigits/>
            <CardsMain/>
            {/*<CommentSlider/>*/}
            {/*<Faq/>*/}
            <Form/>
            <SliderPartners/>
            <Footer/>
        </div>
    );
}

export default App;
