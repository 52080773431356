import { useEffect } from 'react';
import styles from './dnkanim.module.scss';

export const DnkAnim = () => {
    const words = "webdevelopment";
    const ANGLE = 360;
    const ANIMATION_DURATION = 4000;
    const isFirefox = typeof InstallTrigger !== 'undefined';

    const animation = () => {
        let angle = ANGLE;
        const animate = () => {
            angle -= 1;
            document.querySelectorAll(`.${styles.spiral} *`).forEach((el, i) => {
                const translateY = Math.sin(angle * (Math.PI / 120)) * 100;
                const scale = Math.cos(angle * (Math.PI / 120)) * 0.5 + 0.5;

                const offset = parseInt(el.dataset.offset);
                const delay = i * (ANIMATION_DURATION / 16) - offset;

                setTimeout(() => {
                    el.style.transform = `translateY(${translateY}px) scale(${scale})`;
                }, delay);
            });

            requestAnimationFrame(animate);
        };

        requestAnimationFrame(animate);
    };

    useEffect(() => {
        const createElement = (char, i, offset) => {
            const div = document.createElement("div");
            div.innerText = char;
            div.classList.add(styles.character);
            div.setAttribute("data-offset", offset);
            div.style.animationDelay = `-${i * (ANIMATION_DURATION / 16) - offset}ms`
            return div;
        };

        const spiral1 = document.querySelector(`#spiral`);
        const spiral2 = document.querySelector(`#spiral2`);

        words.split("").forEach((char, i) => {
            spiral1.append(createElement(char, i, 0));
            spiral2.append(createElement(char, i, (isFirefox ? 1 : -1) * (ANIMATION_DURATION / 2)));
        });

        if (isFirefox) {
            animation();
        }
    }, [isFirefox]);

    return (
        <div className={styles.dnkanim}>
            <div id="spiral" className={styles.spiral}></div>
            <div id="spiral2" className={styles.spiral}></div>
        </div>
    );
};

