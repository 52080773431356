import {Counter} from "./Countup";
import styles from './counter.module.scss'

export function CounterDigits() {
    return (
        <div className='container'>
            <div className={styles.wrapper}>
                <h2>
                    <Counter className={styles.count} end={3} duration={2.5} suffix="М+"/>
                    <h1>Отправленных лидов</h1>
                </h2>
                <h2>
                    <Counter className={styles.count} end={300} duration={2} suffix="+"/>
                    <h1>Проведенных интеграций</h1>

                </h2>
                <h2>
                    <Counter className={styles.count} end={20} duration={3} suffix="+"/>
                    <h1>Постоянных партнеров</h1>
                </h2>
            </div>
        </div>
    )
}
