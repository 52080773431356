import styles from "./project.module.scss"

import {Item} from "./Item";


export function Project() {

    const items = [
        {
            title: "CRM",
            text: 'Вы получаете передовую платформу, которая постоянно развивается и становится лучше, исходя из реальных потребностей наших клиентов.',
            span: 'Ваш арбитражный трафик: взлет с CRM-системой нового поколения!',
            btn: 'Подробнее',
            path: '/crm',
            type: "promotion"
        },
        {
            title: "DEV",
            text: 'Не упустите шанс повысить свою прибыль и достичь новых высот в арбитраже трафика получив качественные IT услуги',
            span: 'Займитесь только рекламными компаниями, а технические заморочки оставьте на нас',
            btn: 'Подробнее',
            path: '/dev',
            type: "origin"
        },
    ];


    return(
        <section className={styles.projects}>
            <h2>Что мы предлагаем</h2>
            <div className={styles.projects_block}>
                {items.map((card, index) => (
                    <Item
                        key={index}
                        title={card.title}
                        text={card.text}
                        span={card.span}
                        btn={card.btn}
                        path={card.path}
                        type={card.type}
                    />
                ))}
            </div>
        </section>
    )
}