import {Card} from "./Card";
import styles from "../../../crmPage/components/Advantages/Cards/cards.module.scss"

// icons
import individ from "../../../../icons/cardmain1.svg"
import innovation from "../../../../icons/cardmain2.svg"
import quality from "../../../../icons/cardmain3.svg"
import save from "../../../../icons/cardmain4.svg"
import Button from "../../../../shared/Buttons";

export const CardsMain = () => {
    const cards = [
        {
            icon: individ,
            title: 'Индивидуальный подход'
        },
        {
            icon: innovation,
            title: 'Инновации'
        },
        {
            icon: quality,
            title: 'Качество'
        },
        {
            icon: save,
            title: 'Надежность'
        }
    ];

    return (
        <section className={`${styles.card_block} ${styles.card_main}`}>
            <div className="container">
                <h1 className={styles.card_header}>Почему мы</h1>
                <div className={styles.card_list_full}>
                    {cards.map((card, index) => (
                        <Card
                            key={index}
                            image={card.icon}
                            title={card.title}
                        />
                    ))}
                </div>
                <Button className={styles.mainbtn} backgroundColor="none" text="Узнать детальнее" borderType="1px solid #fff"/>
            </div>
        </section>
    );
};