import Header from "../../widgets/Header/Header";
import {HeroBlock} from "./components/Hero/HeroBlock";
import {Advantages} from "./components/Advantages/Advantages";
import {Features} from "./components/Features/Features";
import {Services} from "./components/Services/Services";
import {Footer} from "../../widgets/Footer/Footer";
import {Form} from "../../widgets/Form/Form";
import LogoSlider from "../../widgets/SliderPartners/SliderPartners";


export function CrmPage() {
    return (
        <>
            <Header/>
            <div className="container">
                <HeroBlock/>
                <Advantages/>
                <Features/>
                <Services/>
            </div>
            <Form/>
            <LogoSlider/>
            <Footer/>
        </>
    )
}