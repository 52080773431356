import styles from "./hero.module.scss"
import Button from "../../../../shared/Buttons";
import {ParallaxScene} from "./Paralax";

export function HeroBlock() {
    return (<section className={styles.hero}>
        <div className="container">
            <div className={styles.hero_wrap}>
                <div className={styles.hero_content}>
                    <h1 className={styles.hero_title}>прокачайте свой бизнес с <br/> помощью наших разработчиков</h1>
                    <p className={styles.hero_sub}>Создание офферов и прилендов:
                        Проектирование и дизайн: Разработка макетов страниц, ориентированных на конверсию.</p>
                    <Button backgroundColor="#EAA313" borderType="none" text="Начать сейчас"/>
                </div>
                <ParallaxScene/>
            </div>
        </div>
    </section>)
}