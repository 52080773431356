import CountUp from 'react-countup';

export const Counter = ({ start = 0, end, duration, prefix = '', suffix = '', decimals = 0 , className='' }) => {
    return (
        <div>
            <CountUp
                start={start}
                end={end}
                className={className}
                duration={duration}
                prefix={prefix}
                suffix={suffix}
                decimals={decimals}
            />
        </div>
    );
};