import styles from "./project.module.scss";
import styled from 'styled-components';
import LinkButton from "../../../../shared/Links";
import React from "react";
import startIcon from "../../../../icons/star-icon.svg";


const Trial = styled.div`
    display: 'block';
`;

export const Item = ({ title, text, span, btn, path, type }) => {
    console.log(type)
    return (
        <div className={`${styles.item}`}>
            {type === 'promotion' && <Trial type={type} className={styles.share}>
                <p>Free trial<br/>
                    period</p>
            </Trial>}
            <h2 className={styles.item_title}>{title}</h2>
            <p className={styles.item_text}>{text}</p>
            <span className={styles.item_span}>{span}</span>
            <LinkButton backgroundColor="none" borderType="1px solid #000" text={btn} to={path}/>
        </div>
    );
};