import styles from './feature.module.scss'

export function Feature({ image, title, infoTitle, listText, classType }) {
    return (
        <div className={styles.feature_block}>
            <div className={`${styles.feature_item} ${styles[classType]}`}>
                <div className={styles.feature_image}>
                    <img src={image} alt={title}/>
                </div>
                <div className={styles.feature_info}>
                    <h2>{title}</h2>
                    {infoTitle.map((subTitle, index) => (
                        <div key={index}>
                            <h4>{subTitle}</h4>
                            <p>{listText[index]}</p>
                        </div>
                    ))}
                    {/* Если infoTitle пустой, выводим только текст */}
                    {infoTitle.length === 0 && listText.map((text, index) => (
                        <p key={index}>{text}</p>
                    ))}
                </div>
            </div>
        </div>
    );
}