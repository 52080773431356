import userIcon from "../../../../../icons/user-icon.svg"
import phoneIcon from "../../../../../icons/phone-icon.svg"
import crmDev from "../../../../../icons/api-dev-icon.svg"
import serverIcon from "../../../../../icons/server-icon.svg"
import storageIcon from "../../../../../icons/storage-icon.svg"
import cloudIcon from "../../../../../icons/cloud-setting-icon.svg"
import styles from "./uniqueApi.module.scss"
import React, { useEffect, useRef } from 'react';

const CanvasAnimation = () => {
    const canvasRef = useRef(null);

    useEffect(() => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');

        const icons = [
            { img: new Image(), progress: 0 },
            { img: new Image(), progress: 0 },
            { img: new Image(), progress: 0 },
            { img: new Image(), progress: 0 },
            { img: new Image(), progress: 0 },
            { img: new Image(), progress: 0 },
        ];

        // Assigning images to icons
        icons[0].img.src = userIcon;
        icons[1].img.src = phoneIcon;
        icons[2].img.src = crmDev;
        icons[3].img.src = serverIcon;
        icons[4].img.src = storageIcon;
        icons[5].img.src = cloudIcon;

        const updateCanvasSize = () => {
            const canvasWidth = canvas.parentNode.clientWidth;
            const canvasHeight = canvasWidth / 2;
            canvas.width = canvasWidth;
            canvas.height = canvasHeight;

            const apiCenter = { x: canvasWidth / 2, y: canvasHeight / 2 };
            const margin = 40; // Distance from API center to stop lines
            const iconMargin = 30; // Distance from icons to start lines
            const isMobile = canvasWidth < 600; // Define a breakpoint for mobile devices
            const iconSize = isMobile ? 20 : 40; // Smaller icons on mobile devices

            const iconPositions = [
                { x: apiCenter.x - canvasWidth / 4, y: apiCenter.y - canvasHeight / 4 },
                { x: apiCenter.x - canvasWidth / 3, y: apiCenter.y },
                { x: apiCenter.x - canvasWidth / 4, y: apiCenter.y + canvasHeight / 4 },
                { x: apiCenter.x + canvasWidth / 4, y: apiCenter.y - canvasHeight / 4 },
                { x: apiCenter.x + canvasWidth / 3, y: apiCenter.y },
                { x: apiCenter.x + canvasWidth / 4, y: apiCenter.y + canvasHeight / 4 },
            ];

            icons.forEach((icon, index) => {
                icon.x = iconPositions[index].x;
                icon.y = iconPositions[index].y;
                icon.size = iconSize;
            });

            const drawStatic = () => {
                ctx.clearRect(0, 0, canvas.width, canvas.height);

                // Draw static icons
                icons.forEach(icon => {
                    ctx.drawImage(icon.img, icon.x - icon.size / 2, icon.y - icon.size / 2, icon.size, icon.size);
                });

                // Draw API text
                ctx.fillStyle = 'white';
                ctx.font = `${canvasWidth / 20}px sans-serif`;
                const textWidth = ctx.measureText('API').width;
                ctx.fillText('API', apiCenter.x - textWidth / 2, apiCenter.y + canvasHeight / 30);

                // Draw dashed lines
                ctx.strokeStyle = 'white';
                ctx.lineWidth = 2;
                ctx.setLineDash([5, 5]);
                icons.forEach(icon => {
                    ctx.beginPath();
                    // Calculate start and end points avoiding overlap with icons and API text
                    const angle = Math.atan2(apiCenter.y - icon.y, apiCenter.x - icon.x);
                    const startX = icon.x + iconMargin * Math.cos(angle);
                    const startY = icon.y + iconMargin * Math.sin(angle);
                    const endX = apiCenter.x - margin * Math.cos(angle);
                    const endY = apiCenter.y - margin * Math.sin(angle);
                    ctx.moveTo(startX, startY);
                    ctx.lineTo(endX, endY);
                    ctx.stroke();
                });
                ctx.setLineDash([]); // Reset line dash
            };

            const animateLines = () => {
                let progress = 0;
                const speed = 0.005;
                const ballRadius = 4;

                const drawFrame = () => {
                    drawStatic();

                    icons.forEach(icon => {
                        icon.progress = progress;

                        const totalDistance = Math.sqrt((apiCenter.x - icon.x) ** 2 + (apiCenter.y - icon.y) ** 2) - margin - iconMargin;
                        const currentDistance = icon.progress * totalDistance;
                        const angle = Math.atan2(apiCenter.y - icon.y, apiCenter.x - icon.x);
                        const currentX = icon.x + iconMargin * Math.cos(angle) + currentDistance * Math.cos(angle);
                        const currentY = icon.y + iconMargin * Math.sin(angle) + currentDistance * Math.sin(angle);

                        // Draw the moving ball
                        ctx.fillStyle = 'yellow';
                        ctx.beginPath();
                        ctx.arc(currentX, currentY, ballRadius, 0, 2 * Math.PI);
                        ctx.fill();
                    });

                    progress += speed;
                    if (progress >= 1) {
                        progress = 0;
                    }
                    requestAnimationFrame(drawFrame);
                };

                drawFrame();
            };

            drawStatic();
            animateLines();
        };

        window.addEventListener('resize', updateCanvasSize);
        updateCanvasSize();

        // Wait for images to load before starting the animation
        Promise.all(icons.map(icon => new Promise(resolve => {
            icon.img.onload = resolve;
        }))).then(() => {
            updateCanvasSize();
        });

        return () => {
            window.removeEventListener('resize', updateCanvasSize);
        };
    }, []);

    return (
        <canvas
            ref={canvasRef}
            style={{ display: 'block', margin: '0 auto'}}
        ></canvas>
    );
};


export function UniqueApi() {
    return (
        <>
            <div className={styles.api_content}>
                <h3>Уникальная система API</h3>
                <CanvasAnimation/>
            </div>
        </>
    )
}