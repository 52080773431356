// Header.js
import React from 'react';
import LinkButton from "../../shared/Links";
import styles from "./header.module.css";
import Button from "../../shared/Buttons";
import logo from "../../icons/midas-logo-icon.svg"
import {Link} from "react-router-dom";

const Header = () => {
    const headerStyle = {
        padding: '10px 20px',
        color: 'white',
        textAlign: 'center',
    };


    return (
        <header className={styles.header} style={headerStyle}>
            <nav className={styles.mainMenu}>
                <Link to="/">
                    <img className={styles.logo} src={logo} alt=""/>
                </Link>
                {/*<LinkButton  backgroundColor="none" borderType="1px solid #fff" text="Home" to="/" />*/}
                <LinkButton backgroundColor="none" borderType="1px solid #fff" text="CRM" to="/crm"/>
                <LinkButton backgroundColor="none" borderType="1px solid #fff" text="DEV" to="/development"/>

            </nav>
            <Button className={styles.btn} backgroundColor="#EAA313" borderType="none" text="Связаться с нами"/>

        </header>
    );
};

export default Header;
