import logo from "../../icons/midas-logo-icon.svg"
import styles from "./footer.module.scss"
import facebook from '../../icons/facebook.svg';
import instagram from '../../icons/insta.svg';
import linkedin from '../../icons/linkedin.svg';
import youtube from '../../icons/youtube.svg';
import telegram from '../../icons/icons-telegram.svg'
import LinkButton from "../../shared/Links";
export const Footer = () => {
    return (
        <footer className={styles.footer}>
            <div className={styles.footer_content}>
                <div className={styles.footer_logo}>
                    <img src={logo} alt='logo' />
                </div>

                <div className={styles.footer_social}>
                    <LinkButton to="https://t.me/midas_crm" aria-label="telegram"><img src={telegram} alt=""/></LinkButton>
                </div>
                <div className={styles.footer_copy}>
                    &copy; Midas Company 2019
                </div>
            </div>
        </footer>
    );
};
