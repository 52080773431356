import React from 'react';
import ReactDOM from 'react-dom/client';
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import App from './App';

// fonts
import './app/fonts/Inter-Regular.ttf'
import './app/fonts/Inter-Bold.ttf'
import './app/fonts/Inter-Black.ttf'
import './app/fonts/fonts.css'

// styles
import './app/styles/normalize.css'
import './app/styles/container.css'
import './app/styles/main.css'
import {DevPage} from "./pages/devPage/DevPage";
import {CrmPage} from "./pages/crmPage/CrmPage";


const router = createBrowserRouter([
    {
        path: "/",
        element: <App/>,
    },
    {
        path:"/development",
        element:<DevPage/>
    },
    {
        path:"/crm",
        element:<CrmPage/>
    },
]);

ReactDOM.createRoot(document.getElementById('root')).render(
    <React.StrictMode>
        <RouterProvider router={router} />
    </React.StrictMode>,
)
