import styles from "./hero.module.scss"
import Button from "../../../../shared/Buttons";

export function HeroBlock() {
    return (
        <section className={styles.hero}>
            <div className={styles.hero_wrap}>
                <div className={styles.text_container}>
                    <h1 className={styles.hero_title}>
                        Воспользуйтесь пробным<br/> периодом CRM
                    </h1>
                    <p className={styles.hero_sub}>
                        Боксы для комбинирования работы вебмастеров, с гибкими настройками
                    </p>
                    <p className={styles.hero_sub}>
                        Эффективное управление трафиком: Комбинируйте работу вебмастеров с гибкими настройками боксов
                        для максимальной эффективности и результата
                    </p>
                    <p className={styles.hero_sub}>
                        Автоматизируйте работу с разными сделками и брендами, и просто наблюдайте за их успехом
                    </p>
                    <Button backgroundColor="#EAA313" borderType="none" text="Начать сейчас"/>
                </div>
                <div className={styles.laptop_background}>
                    <iframe className={styles.laptop_video} src="https://drive.google.com/file/d/1B7kWnSjeOvTDIhaBP9SWgDXDaokyF8Ic/preview" allow="autoplay"></iframe>
                </div>
            </div>
        </section>
    );
}