import styles from "./thankYouModal.module.scss"
import logo from "../../icons/midas_logo_white.svg"
export const ThankYouPopup = ({ isOpen, onClose }) => {
    if (!isOpen) {
        return null;
    }

    return (
        <div className={styles.popup_overlay}>
            <div className={styles.popup_content}>
                <img src={logo} className={styles.logo} alt=""/>
                <h2>Спасибо что оставили заявку!</h2>
                <p>Мы свяжемся с вами с ближайшее время</p>
                <button onClick={onClose}>Закрыть</button>
            </div>
        </div>
    );
};