import styles from "./card.module.scss"
export const Card = ({ image, title }) => {
    return (
        <div className={`${styles.card}`}>
            <div className={styles.card_content}>
                <h4 className={styles.card_title}>{title}</h4>
            </div>
            <img src={image} alt={title} className={styles.card_image}/>
        </div>
    );
};