import styles from './logoslider.module.scss';
import hell from '../../images/hell.webp';
import wonderlend from '../../images/wonder.webp';
import ainetwork from '../../images/ainetwork.webp';
import olimp from '../../images/olimp.webp';
import project from '../../images/project.webp';
const LogoSlider = () => {
    return (
        <div className={styles.logoSlider}>
            <div className={styles.logoSlideTrack}>
                <div className={styles.slide}>
                    <img
                        src={wonderlend}
                        alt=""/>
                </div>
                <div className={styles.slide}>
                    <img
                        src={ainetwork}
                        alt=""/>
                </div>
                <div className={styles.slide}>
                    <img
                        src={project}
                        alt=""/>
                </div>
                <div className={styles.slide}>
                    <img
                        src={olimp}
                        alt=""/>
                </div>
                <div className={styles.slide}>
                    <img
                        src={hell}
                        alt=""/>
                </div>
            </div>
        </div>
    );
};

export default LogoSlider;