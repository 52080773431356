import {UniqueApi} from "./UniqueApi/UniqueApi";
import styles from "./advantages.module.scss"
import {Cards} from "./Cards/Cards";
import Button from "../../../../shared/Buttons";


export function Advantages() {
    return (
        <section className={styles.advantages}>
                <h2><span>Преимущества</span> нашей CRM</h2>
                <UniqueApi/>
                <Cards/>
                <Button backgroundColor="none" text="Узнать детальнее" borderType="1px solid #fff"/>
        </section>
    )
}