import {Card} from "./Card/Card";

// icons
import shered from "../../../../../icons/shered-icon.svg";
import setting from "../../../../../icons/setting-icon.svg";
import graf from "../../../../../icons/graf-icon.svg";
import statistic from "../../../../../icons/statistic-icon.svg";

import styles from "./cards.module.scss"

export const Cards = () => {
    const cards = [
        {
            icon: shered,
            title: 'Универсальное решение для баинга и нетворка'
        },
        {
            icon: setting,
            title: 'Удобные статусы'
        },
        {
            icon: graf,
            title: 'Эффективное управление трафиком'
        },
        {
            icon: statistic,
            title: 'Подробная статистика'
        }
    ];

    return (
        <section className={styles.card_block}>
                <div className={styles.card_list_full}>
                    {cards.map((card, index) => (
                        <Card
                            key={index}
                            image={card.icon}
                            title={card.title}
                        />
                    ))}
                </div>
        </section>
    );
};