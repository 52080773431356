import styles from "./card.module.scss"
export const Card = ({ image, title, listItems }) => {
    return (
        <div className={styles.card}>
            <img src={image} alt={title} className={styles.card_image}/>
            <div className={styles.card_content}>
                <h2 className={styles.card_title}>{title}</h2>
                <ul className={styles.card_list}>
                    {listItems.map((item, index) => (
                        <li key={index} className={styles.card_list_item}>{item}</li>
                    ))}
                </ul>
            </div>
        </div>
    );
};