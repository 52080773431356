import React from 'react';
import laptopImage from '../../../../icons/mac.svg';
import devIcon from '../../../../icons/devicon.svg';
import setting from '../../../../icons/setingswithbg.svg';
import jsIcon from '../../../../icons/js-icon.svg';
import htmlIcon from '../../../../icons/html-icon.svg';
import cssIcon from '../../../../icons/cssfile.svg';
import crmIcon from '../../../../icons/crm-icon.svg';
import brainIcon from '../../../../icons/brainwithbg.svg';
import styles from './parallax.module.scss';

export const ParallaxScene = () => {
    const handleMouseMove = (event) => {
        const icons = document.querySelectorAll(`.${styles.icon}`);
        icons.forEach((icon) => {
            const speed = icon.getAttribute('data-speed');
            const x = (window.innerWidth +- event.pageX * speed) / 200;
            const y = (window.innerHeight -+ event.pageY * speed) / 200;
            icon.style.transform = `translateX(${x}px) translateY(${y}px)`;
        });
    };

    return (
        <div className={styles.parallax_scene}>
            <img
                src={laptopImage}
                alt="Laptop"
                data-speed="2"
                className={` ${styles.laptop} ${styles.icon}` }
                onMouseMove={handleMouseMove}
            />
            <img
                src={devIcon}
                alt="Dev"
                className={`${styles.icon} ${styles.icon_dev}`}
                data-speed="5"
            />
            <img
                src={jsIcon}
                alt="JS"
                className={`${styles.icon} ${styles.icon_js}`}
                data-speed="5"
            />
            <img
                src={htmlIcon}
                alt="HTML"
                className={`${styles.icon} ${styles.icon_html}`}
                data-speed="5"
            />
            <img
                src={setting}
                alt="HTML"
                className={`${styles.icon} ${styles.icon_setting}`}
                data-speed="5"
            />
            <img
                src={cssIcon}
                alt="CSS"
                className={`${styles.icon} ${styles.icon_css}`}
                data-speed="5"
            />
            <img
                src={crmIcon}
                alt="CRM"
                className={`${styles.icon} ${styles.icon_crm}`}
                data-speed="5"
            />
            <img
                src={brainIcon}
                alt="Brain"
                className={`${styles.icon} ${styles.icon_brain}`}
                data-speed="5"
            />
        </div>
    );
};

export default ParallaxScene;
