import styles from "./hero.module.scss";
import Button from "../../../../shared/Buttons";
import midas from "../../../../images/midas.png"


export function Hero () {

    return (
        <section className={styles.hero}>
                <div className={styles.hero_block}>
                    <h1 className={styles.hero_title}>Все инструменты для успешного
                        трафика<br/><span>в одном месте</span></h1>
                    <p className={styles.hero_sub}>Мы поможем оптимизировать ваш бизнес</p>
                    <Button backgroundColor="#EAA313" borderType="none" text="Начать сейчас"/>
                    <div className={styles.corners}>
                        <div className={`${styles.corner} ${styles.topLeft}`}></div>
                        <div className={`${styles.corner} ${styles.topRight}`}></div>
                        <div className={`${styles.corner} ${styles.bottomLeft}`}></div>
                    </div>
                    <img className={styles.midas_img} src={midas} alt=""/>
                </div>
        </section>
    )
}